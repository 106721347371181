<template>
  <div>
    <el-card class="mb20" shadow="hover">
      <el-row type="flex" :gutter="20" align="middle">
          <el-input
            :placeholder="$t('search')"
            clearable
            v-model="filter.search"
            @keyup.enter.native="get_list"
          ></el-input>
      </el-row>
      <el-row class="mt20" type="flex" align="middle">
          <el-button @click="get_list" type="primary">{{$t('search_zh')}}</el-button>
          <el-button @click="reset" type="info">{{$t('btnTip.reset')}}</el-button>
        </el-row>
    </el-card>
    <el-card>
      <div class="flexBC mb20">
        <div></div>
        <el-button
          type="primary"
          @click="outExe"
          >{{$t('outExe')}}</el-button>
      </div>
      <my-table
        :columns="columns"
        :data="list_data.list"
        :showIndex="false"
        :showSelection="false"
        :cellStyle="{padding: '6px 0'}"
        :headerCellStyle="{background:'rgba(51, 55, 68, 1)',color:'#fff'}"
        @emitSelection="allSelect"
        @sortChange="sort_change"
      >
        <template v-slot:content="slotProps">
          <div v-html="slotProps.callback.row.content">
          </div>
        </template>
        <template v-slot:status="slotProps">
          <el-tag :type="statusTag(slotProps.callback.row.status)">{{status(slotProps.callback.row.status)}}</el-tag>
        </template>
        <template v-slot:active="slotProps">
          <el-tag :type="activeTag(slotProps.callback.row.active)">{{active(slotProps.callback.row.active)}}</el-tag>
        </template>
        <template v-slot:operation="slotProps">
          <el-button
              @click="handleClick('top',slotProps.callback.row)"
              :type="slotProps.callback.row.is_top ? 'info' : 'success'"
              size="mini"
            >{{slotProps.callback.row.is_top ? $t('wrong.is_top0') : $t('wrong.is_top1')}}</el-button>
          <el-tooltip class="item" effect="dark" :content="slotProps.callback.row.active === 0
                ? $t('wrong.active1')
                : $t('wrong.active0')" placement="top">
            <el-button
              @click="handleClick('active',slotProps.callback.row)"
              :type="slotProps.callback.row.active === 0 ? 'success' : 'warning'"
              size="mini"
            >{{
                slotProps.callback.row.active === 0
                  ? $t("wrong.active1")
                  : $t("wrong.active0")
              }}</el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" :content="$t('btnTip').edit" placement="top">
            <el-button
              @click="handleClick('edit',slotProps.callback.row)"
              type="warning"
              size="mini"
            >{{$t('wrong.toQuestion')}}</el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" :content="$t('btnTip').delete" placement="top">
            <el-button
              @click="remove(slotProps.callback.row.id)"
              icon="el-icon-delete"
              type="danger"
              size="mini"
            ></el-button>
          </el-tooltip>
        </template>
      </my-table>
      <page :total="list_data.total" :page_size.sync="pageSize" :page.sync="page" />
    </el-card>
  </div>
</template>

<script>
import {  mixin_list } from "@/mixins";
import myTable from '@/components/table'
import page from "@/components/page";
import {  getWrong, wrongDelete, wrongQuestion, topWrong } from '@/api/index';
export default {
  mixins: [ mixin_list(getWrong)],
  components: {
    page,
    myTable
  },
  data () {
    return {
      filter: {
        search: '',
      },
      columns: [
        {
          label: 'ID',
          prop: 'id',
          sortable:true,
          width:60
        }, 
        {
          label: this.$t('wrong.create_time'),
          prop: 'create_time',
          sortable:true,
          width:100
        },
        {
          label: this.$t('wrong.update_time'),
          prop: 'update_time',
          sortable:true,
          width:100
        },
        {
          label: this.$t('wrong.title'),
          prop: 'title',
          sortable:true,
          width:320
        },
        // {
        //   label: this.$t('wrong.subject'),
        //   prop: 'subject',
        //   sortable:true
        // },
        // {
        //   label: this.$t('wrong.chapter'),
        //   prop: 'chapter',
        //   sortable:true
        // },
        // {
        //   label: this.$t('wrong.examination'),
        //   prop: 'examination',
        //   sortable:true
        // },
        {
          label: this.$t('wrong.user'),
          prop: 'user',
          sortable:true
        },
        {
          label: this.$t('wrong.category'),
          prop: 'category',
          sortable:true
        },
        {
          label: this.$t('wrong.content'),
          prop: 'content',
          sortable:true,
          slot:'content'
        },
        {
          label: this.$t('wrong.status'),
          prop: 'status',
          slot:'status',
          sortable:true,
          width:100
        },
        {
          label: this.$t('wrong.active'),
          prop: 'active',
          slot:'active',
          sortable:true,
          width:100
        },
        {
          label: this.$t('operation'),
          prop: '',
          slot: 'operation',
          fixed:'right',
          width:350
        }]
    }
  },
  methods: {
      active(active){
          switch(active){
              case 0:
                  return this.$t('wrong.active0')
                case 1:
                  return this.$t('wrong.active1')
          }
      },
      activeTag(activeTag){
        switch(activeTag){
          case 0:
            return 'info'
          case 1:
            return 'success'
        }
      },
      status(status){
          switch(status){
              case 0:
                  return this.$t('wrong.status0')
                case 1:
                  return this.$t('wrong.status1')
          }
      },
      statusTag(statusTag){
        switch(statusTag){
          case 0:
            return 'info'
          case 1:
            return 'success'
        }
      },
    async handleClick (value, item) {
      this.clickType = value
      this.dialogFormVisible = true
      switch (value) {
        case 'edit':
            this.$router.push(`/customerCenter/wrong/question?id=${item.id}`)
          break;
        case 'active':
            await wrongQuestion({...item,active:item.active===0?1:0})
            this.get_list()
            break;
        case 'top':
          await topWrong({id: item.id,is_top:!item.is_top})
          this.get_list()
      }
    },
    remove (id) {
      this.$confirm(this.$t('sureDelete'), this.$t('tips'), {
        confirmButtonText: this.$t('btnTip.submit'),
        cancelButtonText: this.$t('btnTip.cancel'),
        type: 'warning'
      }).then(async () => {
        await wrongDelete(id)
        this.get_list()
      }).catch(() => {
        
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.text{
  width:100px;
  font-size: 16px;
}
/deep/ label{
  margin-bottom:0!important
}
</style>